import {edgeCompare, getCalculatePixel, getEdgeLetter, onePixelSize} from "../../../pages/CuttingChart/helpers";
import getT from "../../getT";
import detailInfoList from "../detailInfoList";
import React from "react";
import boreXnc from "../operationXnc/boreXnc";
import hemXnc from "../operationXnc/hemXnc";
import pazXnc from "../operationXnc/pazXnc";
import througXnc from "../operationXnc/througXnc";
import srezXnc from "../operationXnc/srezXnc";
import frezXnc from "../operationXnc/frezXnc";
import cutXnc from "../operationXnc/cutXnc";
import {ASSOCIATION_OWNER_DROP, getDropOwnerTypeOfMaterial} from "../../../helpers/helper-cutting-owner";

const LANG = localStorage.getItem("lang");
const SCALE = 0.25;
const styleForMode = (modeDetailing) => {
    return `
  <style>   
   @media print {
          .cutting-main-view{
          margin-bottom: 0px !important;
          padding-bottom: 0px !important;
          }
        }    
     @page {
            size:auto;
          }
          .cutting-chart-detail-head p,
          .cutting-chart-detail-head span{
          line-height: 14px;
          font-size: 13px !important;
          }
          .cutting-chart-detail-head p{
          margin-top: 8px;
          margin-bottom: 8px;
          }

        .cutting-material-result{
       display: none !important;
       }
    body .cutting-main-view{ 
       page-break-inside: avoid;
       page-break-after: always;
    }
    .cutting-main-view{
    width: 100%;
    }
    .cutting-main-view .mode {
      width: 100%;
      display: flex;
      align-items: flex-start;
    }
  .cutting-main-view .mode  table{
    width: max-content;
    margin-left: 8px;
    -webkit-print-color-adjust: exact !important; color-adjust: exact !important;
  }
    .cutting-main-view .mode .cutting-chart-view__item{
        margin-bottom: 0px;
    }
.cutting-main-view .mode th, .cutting-main-view .mode td {
    padding: 3px;
    font-size: 12px;
    text-align: center;
}

.cutting-main-view.mode .cutting-chart-detail-head {
    flex: 0 0 100%;
}
span.t, span.b, span.l, span.r,
span.count,
span.h,
span.w {
font-size: ${modeDetailing ? '15px' : '20px'};
line-height: ${modeDetailing ? '13px' : '16px'};
}
    .cutting-chart-view__item{
margin: ${modeDetailing ? '0px' : '0 auto'};
}
    </style>
  `
}

export default function ({order, cutting, partsPreview, visibleTotalXnc, mode = false, modeDetailing = false}) {
    const totalInfoCutting = cutting?.total_info?.split("||");
    const {part_xnc_total} = cutting;

    if (cutting && cutting.hasOwnProperty("cards") && Array.isArray(cutting.cards) && cutting.cards.length) {
        const cuttingCards = cutting.cards.map((card, index) => {
            const repeatCard = cutting.cards.filter(item => (Number(item.goods_id) === Number(card.goods_id)));
            return CuttingView({
                partsPreview,
                cutting, order, card, childrenMaterialResult: repeatCard, mode, modeDetailing, index: index
            })
        })?.join('')

        return `
    ${mode ? styleForMode(modeDetailing) : ''}
     <div class="cutting-view-wrap">
    ${cuttingCards}
    </div>
    
    ${mode ? '' : TotalInfoCuttingOfMaterial({totalInfoCutting})}
    ${mode ? '' : visibleTotalXnc ? DetailPartTotalXnc({part_xnc_total}) : ''}
    `;

    } else {
        return `not cutting card`;
    }
}

function DetailsShortEdging({parts, band}) {
    let lang = localStorage.getItem("lang");
    let edgingRow = band
        ?.filter(bandItem => {
            return parts
                ?.find(part => {
                    let edge = part.edge;
                    return Object.keys(edge).some(key => Number(edge[key].db_id) === Number(bandItem.goods_id))
                });

        })?.map(bandItem => {
            return `
    <tr>
      <th scope="col">${bandItem?.letter ?? ''}</th>
      <td colspan="4">${bandItem?.translate?.[lang] ?? bandItem?.name}</td>
    </tr>
    `
        })?.join('')
    return edgingRow ?? ''
}

function DetailsShortFrez({part, band}) {
    let lang = localStorage.getItem("lang");
    let frezRow = band?.filter(bandItem => {
        return part?.find(item => {
            return item.operations?.frez?.some(frez => Number(frez?.db_id) === Number(bandItem?.goods_id))
        });
    })?.map(bandItem => {
        return `
        <tr>
           <th scope="col">${bandItem?.letter ?? ''}</th>
           <td colspan="4">${bandItem?.translate?.[lang] ?? ''}</td>
        </tr>
    `
    })?.join('');

    return frezRow ? `
     <th colspan="5" scope="col">${getT('кромкование фрезерного реза')}</th>
      ${frezRow}
  ` : ''
}

function DetailingTable({parts, card, edging_total, band}) {

    const findParts = parts?.filter(p => {
        return card?.some(detail => Number(p?.id) === Number(detail?.part_id))
    });


    const detailing = card?.sort((a, b) => a?.part_id - b?.part_id)?.map(detail => {
        const findPart = parts?.find(p => Number(p?.id) === Number(detail?.part_id));

        return `<tr>
      <th scope="col">${detail?.part_id !== 'rest' ? detail?.part_id : '-'}</th>
     <td >${detail?.length ?? ''}</td>
      <td >${detail?.width ?? ''}</td>
      <td>${detail?.count ?? ''}</td>
      <td class="table-active">${Number(detail?.texture) === 1 ? '✓' : ''}</td>
    </tr>`
    })?.join('')
    return `
  <table class="table table-bordered">
  <tr class="table-active">
      <th scope="col">№</th>
      <th scope="col">x</th>
      <th scope="col">y</th>
      <th scope="col">Σ</th>
      <th scope="col">txt</th>
    </tr>
    ${detailing}
    ${DetailsShortEdging({parts: findParts, band: band})}
      ${DetailsShortFrez({part: findParts, band: band})}
  </table>
  `
}


function CuttingView({cutting, card, childrenMaterialResult, order, partsPreview, mode, modeDetailing, index}) {

    // -----------state------------
    const {id: orderId, material: materialState, part: parts, calculate} = order;
    const {total_info, edging, part_xnc, lbl: cuttingPreview} = cutting;
    // -----------------------
    let totalInfo = total_info?.split("||");

    let is_max_side_width = Number(card.x) > Number(card.y);
    let max_side = is_max_side_width ? {value: Number(card.x), side: 'x'} : {
        value: Number(card.y),
        side: 'y'
    };
    const pixel = onePixelSize({side: max_side.side, wSide: max_side.value});

    // -----------------------
    const material = materialState.filter(m => Number(m.goods_id) === Number(card.goods_id))?.[0] ?? false;
    const cut = card.hasOwnProperty("cut_card") ? [...card.cut_card] : [];
    //------------------------
    let materialWithStock = Array.isArray(calculate?.material) ? calculate?.material?.find(e => Number(e?.id) === Number(card.goods_id)) : null;
    let calcTypeWithStock = materialWithStock?.calc_type;
    let resToClientMaterial = Number(material?.res_to_client); // 1 -rest client , 0-rest firm

    // let matRestOwner = (calcTypeWithStock === "m" || (calcTypeWithStock === "sheet" && resToClientMaterial == 0))
    //     ? "firm"
    //     : (calcTypeWithStock === "sheet" && resToClientMaterial == 1) ? 'client' : null;
    let matRestOwner = getDropOwnerTypeOfMaterial(calcTypeWithStock, resToClientMaterial)


    //--------------------------
    let REST_PART_KEY = "rest"
    const card_cut_new = cut?.filter(item => item.part_id !== "cut")
        ?.reduce((acc, c) => {
            let is_rest_part = c.part_id === REST_PART_KEY;
            let acc_key = is_rest_part ? `${REST_PART_KEY}_${c?.length}_${c.width}` : `item_${c.part_id}_${c?.length}_${c.width}`
            let part = parts.find(p => Number(p.id) === Number(c.part_id));
            let preview = cuttingPreview?.find(p => Number(p.part_id) === Number(c.part_id));
            let duplicatePartId = cut.filter(item => (item.part_id == c.part_id) && (Number(c.length) === Number(item.length)) && (Number(c.width) === Number(item.width))).length || 1;

            if (acc.hasOwnProperty(acc_key)) return acc
            if (is_rest_part) {
                return {
                    ...acc,
                    [acc_key]:
                        {
                            ...c,
                            count: Number(duplicatePartId) * Number(card.count),
                            z: c.hasOwnProperty("z") ? c.z : "",
                            name: "",
                            link: false
                        }
                }
            }
            if (part) {
                return {
                    ...acc, [acc_key]:
                        {
                            ...c,
                            count: Number(duplicatePartId) * Number(card.count),
                            z: c.hasOwnProperty("z") ? c.z : part?.z ?? "",
                            name: part?.name ?? "",
                            link: preview && preview.hasOwnProperty("link") ? preview.link : false,
                            texture: part?.texture ?? 0
                        }
                }
            }
            return acc


        }, {});
    const uniqCards = Object.values(card_cut_new);

    const renderDetailItems = card.hasOwnProperty("cut_card") && card.cut_card.filter(item => item.part_id !== "cut").map((detail, ind) => {

        let is_number_part_id = !!Number(detail.part_id)
        let order_part = is_number_part_id ? parts?.find(item => Number(item.id) === Number(detail.part_id)) : null;
        let preview = is_number_part_id ? Array.isArray(partsPreview) ? partsPreview?.filter(img => Number(img?.id) === Number(detail.part_id)) : false : false;
        const edge = edging?.filter(edge => Number(edge.part_id) === Number(detail.part_id))[0] || {};
        let stitching = detail.pd_id ? Number(detail.part_id) >= 0 : false;
        let ownerUi = ASSOCIATION_OWNER_DROP?.[card.ownerUiCutDropId]?.val ?? null;
        let ownerCompare = detail.owner === "no" ? ownerUi ? ownerUi : detail.owner : detail.owner;
        return `${DetailItem({
            width: getCalculatePixel({pixel: pixel, value: detail.length}),
            height: getCalculatePixel({pixel: pixel, value: detail.width}),
            top: getCalculatePixel({pixel: pixel, value: Number(detail.y - 4)}),
            left: getCalculatePixel({pixel: pixel, value: Number(detail.x - 4)}),
            options: {
                l: detail.length,
                w: detail.width
            },
            idPart: detail.part_id,
            srcImg: (preview ? preview?.[0]?.svg : false),
            turnImg: (Number(detail.length) !== Number(detail.width) ? Number(preview?.[0]?.length) === Number(detail?.width) : false),
            stitching: stitching,
            edging: edgeCompare(edge, order_part?.edge),
            index: ind,
            owner: (matRestOwner || ownerCompare),
        })}
    `
    })?.join("");

    const styleView = ` width: ${getCalculatePixel({pixel, value: card.x})}px;
            height: ${getCalculatePixel({pixel: pixel, value: card.y})}px;
             zoom: ${(getCalculatePixel({pixel, value: card.y}) >= 590 && index === 0 || getCalculatePixel({
        pixel,
        value: card.y
    }) >= 600) ? 0.85 : 1};
             position: relative;
              border: 2px solid black`;
    return `
    <div class="${(getCalculatePixel({
        pixel: pixel,
        value: card.y
    }) >= 590) ? 'cutting-main-view  ' : 'cutting-main-view'}" style="" >
    
      ${DetailHead({material, card, part: uniqCards, modeTitle: mode ? order?.title : '', orderId: orderId})}
      ${card.visible_card ?
        `

<div class="${mode ? 'mode ' : ''}" >
        <div class="cutting-chart-view__item">
            <div class="cutting-chat-view  line_red" style="${styleView}">
              <div>
                    ${renderDetailItems}
              </div>
            </div>
      </div>
      ${mode && modeDetailing ? DetailingTable({
            card: uniqCards,
            parts: parts,
            edging_total: cutting?.edging_total,
            band: order.band
        }) : ''}
      </div>
`
        : ''}
      
      
    
    
    ${!mode && card.visible_xnc ? DetailTable({card: uniqCards, part_xnc}) : ''}
    ${!mode && (Array.isArray(childrenMaterialResult) && childrenMaterialResult?.length >= 2 && (Number(childrenMaterialResult[childrenMaterialResult.length - 1]?.card_id) === Number(card.card_id))) ? MaterialResult({
        goodsId: card.goods_id,
        materialsTotal: cutting.total_info_mat,
        materials: materialState
    }) : ''
    }
       
</div>
  `
}

function MaterialResult({goodsId, materialsTotal, materials}) {
    const currentMaterial = materialsTotal.filter(material => Number(material.goods_id) === Number(goodsId))?.[0] ?? false;
    const material = materials?.filter(m => Number(m.goods_id) === Number(goodsId))[0] ?? false;

    const infoText = currentMaterial ? currentMaterial?.text?.split('||') : false;
    if (!currentMaterial) return '';

    return `
<div class='cutting-material-result'>
            <div class="">
                <h5>${getT('Итог раскроя по материалу в картах')} <br/>
                ${
        material ? `${material.id}  [${material.translate?.hasOwnProperty(LANG) ? material.translate[LANG] : material.name}]`
            :
            `${material.x && material.y ? `${material?.x}/${material?.y}` : ''}`
    }
                </h5>
                <ul style={"list-style: circle">
                    ${infoText && infoText.map(i => `<li style={"list-style: circle">${i}</li>`)?.join('')}
                </ul>
            </div>
        </div>
`
}

function DetailItem({
                        owner, width, height, top, left, options, idPart, srcImg, stitching, edging, turnImg
                    }) {
    const styleView = ` width: ${width}px;
            height: ${height}px;
           
            position: absolute;
            top: ${top}px;
            left: ${left}px;
            background: white`
    return `
    <div class="${(Number(height) < 45) ? 'cutting-chart-detail min-h' : ((Number(height) < 45) && (Number(width) < 50)) ? 'cutting-chart-detail min-h min-side' : 'cutting-chart-detail '}" style="${styleView}">

            ${stitching ?
        `<div class='stitching-cut'>
                   <span class="${(Number(width) < 50) ? 'w turn' : 'w '}">${options?.l ?? ''}</span>
                   <span class="${Number(width) < 50 ? 'h turn' : 'h'}" >${options?.w ?? ''}</span>
                  <span class="count">[PD ${idPart}]</span>
               </div>`
        :
        DetailCut({srcImg, owner, idPart, options, edging, turnImg, width, height})
    }

        </div>
  `;
}

function DetailCut({srcImg, idPart, options, edging, turnImg, width, height, owner}) {
    const styleView = turnImg ? ` width: ${height}px;
            height: ${width}px;
            position: absolute;
            transform:rotate(90deg)` : ''
    const styleImg = turnImg ? `width:${height}px; height:${width}px` : `width: ${width}px; height:${height}px`
    // let restTitle = owner === "firm" ? 'Остаток Компании' : owner === "client" ? 'Остаток Клиента' : 'Неизвестно'
    let restTitle =  'Остаток';
    let restClassName =  "line_green"
    // let restClassName = owner == "firm" ? ' line_grey' : owner === "client" ? "line_green" : " line_orange"
    return `
          ${srcImg ?
        `<div class="preview" style='width:100%;height: 100%'>
                  <div style="${styleView}">
                <img src=${'data:image/svg+xml;base64,' + srcImg} alt=""  />
                </div>  
            </div>`
        :
        ` <div class="detail-rest ${restClassName}"></div>`
    }
            <span class="${Number(width) < 50 ? 'w turn' : 'w'}">${options.l}</span>
            <span class="${Number(width) < 50 ? 'h turn' : 'h'}">${options.w}</span>
            <span class="count">${!!Number(idPart) ? idPart : getT(restTitle)}</span>
            <span class="${turnImg ? 'r' : 't'}">
                ${getEdgeLetter({edgeSide: edging, key: 'edge', side: 't'})}
                ${getEdgeLetter({edgeSide: edging, key: 'srez_edge', side: 't'})}
            </span>
            <span class="${turnImg ? 'l' : 'b'}">
                ${getEdgeLetter({edgeSide: edging, key: 'edge', side: 'b'})}
                ${getEdgeLetter({edgeSide: edging, key: 'srez_edge', side: 'b'})}
            </span>
            <span class="${turnImg ? 't' : 'l'}">
                ${getEdgeLetter({edgeSide: edging, key: 'edge', side: 'l'})}
                ${getEdgeLetter({edgeSide: edging, key: 'srez_edge', side: 'l'})}
            </span>
            <span class="${turnImg ? 'b' : 'r'}">
                ${getEdgeLetter({edgeSide: edging, key: 'edge', side: 'r'})}
                ${getEdgeLetter({edgeSide: edging, key: 'srez_edge', side: 'r'})}
            </span>
  `
}

function DetailHead({material, card, part, modeTitle, orderId}) {
    const {name, translate, x, y, z, id} = material;
    const {card_id, x: card_x, y: card_y, count: card_count} = card;
    // ------------
    let infoArray = card?.text?.split("||");
    const partArrayInfo = part.map(p => {
        return `[id = ${p.part_id}] ${p.name}`;
    })?.join("");
    // ------------
    return `<div class="cutting-chart-detail-head">
            <h6 style="font-size:13px;">
            [id:${orderId}] 
            [${card_x} | ${card_y} * ${card_count}]
           ${modeTitle ? `(${modeTitle})` : ''}
              ${material ? `${id} [${translate?.hasOwnProperty(LANG) ? translate[LANG] : name}]` : `${partArrayInfo}  ${` [PD(${card?.goods_id_first ?? ''}) / (${card?.goods_id_second ?? ''})]`} `}
            </h6>
            ${detailInfoList({infoArray: infoArray})}
            <p style="font-size: 13px"><strong>${getT("Карта кроя")} id=${card_id} x=${card_x}, y=${card_y},  ${getT('количество')} ${card_count}</strong></p>
  </div>
  `;
}

function DetailTable({card, part_xnc}) {
    const renderXnc = card?.map(detail => {
        const xnc = part_xnc?.filter((item) => Number(item.part_id_op_xnc) === Number(detail.part_id))[0] || false;

        return `
       <tr class="table-warning">
                <th scope="row">${!!Number(detail.part_id) ? detail.part_id : "-"}</th>
                <td>${detail?.count ?? ''}</td>
                <td>${detail?.name ?? ''}</td>
                <td>${detail?.pd_id ? "" : (detail?.length ?? '')}</td>
                <td>${detail?.pd_id ? "" : (detail?.width ?? '')}</td>
                <td>${detail?.z ?? ''}</td>
          
       </tr>
       ${xnc ? ` <tr><td colspan="12" style="padding: 0px;">
 ${DetailTabletXnc({partXnc: xnc})}
</td>
</tr>
` : ''}

`
    })?.join('')

    return `
   <table class="table table-bordered" style="  page-break-inside: auto;">
      <tr>
        <th>id</th>
        <th> Σ</th>
        <th>${getT("Название")}</th>
        <th>X</th>
        <th>Y</th>
        <th>Z</th>
      </tr>
<!--     <tbody>-->
          ${renderXnc}
<!--    </tbody>-->
   </table>
  `
}


function DetailTabletXnc({partXnc}) {
    const xncDom = Object.entries(partXnc).map(([k, v]) => {
        switch (k) {
            case 'bore': {
                return boreXnc({xnc: v})
            }
            case 'fem': {
                return hemXnc({xnc: v})
            }
            case 'paz': {
                return pazXnc({xnc: v})
            }
            case 'through': {
                return througXnc({xnc: v})
            }
            case 'srez': {
                return srezXnc({xnc: v})
            }
            case 'frez': {
                return frezXnc({xnc: v})
            }
            case 'cut_to': {
                return cutXnc({xnc: v})
            }
            default: {

            }
        }
    })?.join('')


    return `
 

            ${xncDom}
     
  `
}

function TotalInfoCuttingOfMaterial({totalInfoCutting}) {
    const renderTotal = totalInfoCutting?.map(i => `<li style="list-style:circle">${i}</li>`)?.join("")
    return `
<hr/>
  <div style="margin: 25px 0px">
   <h5>${getT("Итог раскроя по всем материалам")} </h5>
  <ul>
  ${renderTotal}
</ul>
</div>
  `
}

function DetailPartTotalXnc({part_xnc_total}) {
    return `
<div style="margin: 25px 0px">
 <h5>${getT('Статистика ЧПУ операций по заказу')}</h5>
<!--    <table class="table table-striped" style="margin-bottom: 0px;">-->
   <table class="table-bordered table table-striped" >
            <tbody>
            ${boreXnc({xnc: part_xnc_total, type_name: getT('Сверление')})}
            ${pazXnc({xnc: part_xnc_total, type_name: getT('Пазование')})}
            ${hemXnc({xnc: part_xnc_total, type_name: getT('Сшивка')})}
            ${througXnc({xnc: part_xnc_total, type_name: getT('Выемка')})}
            ${srezXnc({xnc: part_xnc_total, type_name: getT('срез торца по стороне детали')})}
            ${frezXnc({xnc: part_xnc_total, type_name: getT('Фрезерование')})}
            ${cutXnc({xnc: part_xnc_total, type_name: getT('урезка после ЧПУ обработки')})}
</tbody>
        </table>
        </div>
  `
}