export const blueprintJs = params => (`
// scaling 
function scaleAndCenterBlueprintBackground() {
    let { x, y, width, height } = blueprintContainer.getBoundingClientRect();
    let { scrollX, scrollY } = getParentElementScroll()
    blueprintBackground.setAttribute('x', x + scrollX);
    blueprintBackground.setAttribute('y', y + scrollY);
    blueprintBackground.setAttribute('width', width);
    blueprintBackground.setAttribute('height', height);
}
function centerBlueprintContainer(drop = false) {
    if (drop) {
        blueprintContainer.setAttribute('transform', '');
        return;
    }

    let { width: svgWidth,
        height: svgHeight } = blueprint.getBoundingClientRect();
    let { width: containerWidth,
        height: containerHeight } = blueprintContainer.getBoundingClientRect();

    translateX = (svgWidth - containerWidth) / 2;
    translateY = (svgHeight - containerHeight) / 2;
    translateX = Math.max(0, translateX);
    translateY = Math.max(0, translateY);
    blueprintContainer.setAttribute(
        'transform', \`translate(\${ translateX } \${ translateY })\`
    );
}

function scaleBlueprint(_scale = null) {
    centerBlueprintContainer(true);

    if (_scale)
        scale = _scale

    blueprintInnerMain.style.scale = scale;
    blueprintInnerNoMeasurements.style.scale = scale;

    for (b of [blueprintInnerMain, blueprintInnerNoMeasurements]) {
        for (el of b.querySelectorAll('g[double_measurement]')) {
            el.style.scale = 1 / scale;
        }
        for (el of b.children || []) {
            if (el.tagName !== 'text') continue;
            el.style.scale = 1 / scale;
        }
        for (el of b.querySelectorAll('[frez_text]')) {
            el.style.scale = 1 / scale;
        }
        for (op_id_t of b.querySelectorAll('.operation_id')) {
            op_id_t.style.scale = 1 / scale;
        }
        for (mac_radius of b.querySelectorAll('.mac_radius')) {
            mac_radius.style.scale = 1 / scale;
        }
    }
    parentElement.scrollTo(0, 0);
    // enlargeMeasurementsAndResizeAndMoveBlueprint();

    updateButtonsLocationAndCenterBlueprint();
    // scaleAndCenterBlueprintBackground(); 
}
function getParentElementScroll() {
    let scrollX = parentElement.scrollLeft || parentElement.scrollX || 0,
        scrollY = parentElement.scrollTop || parentElement.scrollY || 0;
    return { scrollX, scrollY };
}
function updateButtonsLocationAndCenterBlueprint() {
    let width = parentElement.offsetWidth || parentElement.innerWidth || 0,
        height = parentElement.offsetHeight || parentElement.innerHeight || 0;
    let { scrollX, scrollY } = getParentElementScroll();
    let blueprintInnerRect = blueprintInner.getBoundingClientRect();
    blueprint.setAttribute('width',
        Math.max(width, blueprintInnerRect.width + 20));
    blueprint.setAttribute('height',
        Math.max(height, blueprintInnerRect.height + 20));

    zoomIn.setAttribute('x', width - 80 + scrollX);
    zoomIn.setAttribute('y', 40 + scrollY);

    const zoomOutBBox = zoomOut.getBBox();
    zoomOut.setAttribute('x', width - 80 + scrollX);
    zoomOut.setAttribute('y', 80 + scrollY);
    
    toggleMeasurements.setAttribute('x', width - 80 + scrollX);
    toggleMeasurements.setAttribute('y', 120 + scrollY);
    
    toggleIDs.setAttribute('x', width - 73 + scrollX);
    toggleIDs.setAttribute('y', 175 + scrollY);
    
    resizeScrollToCenter.setAttribute('x', width - 75 + scrollX);
    resizeScrollToCenter.setAttribute('y', 189 + scrollY);
    toggleDoubleMeasurements.setAttribute('x', width - 79 + scrollX);
    toggleDoubleMeasurements.setAttribute('y', 209 + scrollY);

    centerBlueprintContainer();
    moveOperationTable(scrollX, scrollY);
    movePartSizeTextTable(scrollX, scrollY);
    scaleAndCenterBlueprintBackground();
}

function setButtonActive(btn, active) {
    if (active) {
        btn.classList.remove('inactive');
        btn.classList.add('active');
    } else {
        btn.classList.add('inactive');
        btn.classList.remove('active')
    }
}

function toggleMeasurementsHandler() {
    toggleMeasurementsBool = !toggleMeasurementsBool;

    if (toggleMeasurementsBool) {
        scale = tempScale;
        blueprintInnerNoMeasurements.setAttribute('display', 'none');
        blueprintInnerMain.removeAttribute('display');
        blueprintInner = blueprintInnerMain;
        setButtonActive(toggleDoubleMeasurements, false);
    }
    else {
        const toggleShow = () => {
            blueprintInnerMain.setAttribute('display', 'none');
            blueprintInnerNoMeasurements.removeAttribute('display');
        };
        
        if (isFirefox) {
            toggleShow();
        }
            
        let width = parentElement.offsetWidth || parentElement.innerWidth || 0,
            height = parentElement.offsetHeight || parentElement.innerHeight || 0;
        let { width: bWidth, height: bHeight } = blueprintInnerNoMeasurements.getBoundingClientRect();

        tempScale = scale;
        // scale = Math.min(width / (bWidth + 50), height / (bHeight + 50));
        scale = Math.min(width / (bWidth), height / (bHeight));
        console.log(width, bWidth, height, bHeight);
        
        if (!isFirefox) {
            toggleShow();
        }
        
        blueprintInner = blueprintInnerNoMeasurements;
        spreadAllMeasurements();
        setButtonActive(toggleDoubleMeasurements, true);
    }
    scaleBlueprint();
}

function toggleIDsHandler() {
    toggleIDsBool = !toggleIDsBool;
    for (b of [blueprintInnerMain, blueprintInnerNoMeasurements]) {
        for (id of b.querySelectorAll('.operation_id')) {
            if (toggleIDsBool) {
                id.style.visibility = 'visible';
            } else {
                id.style.visibility = 'hidden';
            }
        }
    }
    let ids = blueprintInner
}

function resizeScrollToCenterHandler() {
    const fSide = blueprintInner.querySelector('g[part_side=f]');
    const tSide = blueprintInner.querySelector('g[part_side=t]');
    const bSide = blueprintInner.querySelector('g[part_side=b]');

    let wWidth = window.innerWidth;
    let wHeight = window.innerHeight;
    let fRect = fSide.getBoundingClientRect()
    let tRect = tSide.getBoundingClientRect()
    let bRect = bSide.getBoundingClientRect()

    let distanceToTRect = Math.abs(fRect.top - tRect.top) - tRect.height;
    let distanceToBRect = Math.abs(fRect.top + fRect.height - bRect.top);
    let additionalDistance = 25;

    let nScale = Math.min(
        wWidth / fRect.width * scale,
        wHeight / (fRect.height
            + distanceToTRect
            + tRect.height
            + distanceToBRect
            + bRect.height
            + additionalDistance * 2) * scale,
    )

    scaleBlueprint(nScale);
    spreadAllMeasurements();

    // updating after resize
    fRect = fSide.getBoundingClientRect()

    let x = fRect.left
        + window.scrollX
        - (window.innerWidth - fRect.width) / 2;
    let y = fRect.top
        + window.scrollY
        - (window.innerHeight - fRect.height) / 2;

    window.scrollTo(x, y);
}

function toggleDoubleMeasurementsHandler(toggle = true) {
    if (toggleDoubleMeasurements.classList.contains('inactive'))
        return;

    if (toggle)
        toggleDoubleMeasurementsBool = !toggleDoubleMeasurementsBool;

    localStorage.setItem('BlueprintToggleDoubleMeasurementsBool', toggleDoubleMeasurementsBool);

    let measurementTypeHide;
    let measurementTypeShow;
    let showDoubleIcon = toggleDoubleMeasurements.querySelector('[show-double]')
    let showSingleIcon = toggleDoubleMeasurements.querySelector('[show-single]')

    if (toggleDoubleMeasurementsBool) {
        showDoubleIcon.classList.add('hidden');
        showSingleIcon.classList.remove('hidden');
        measurementTypeHide = 'single-measurement';
        measurementTypeShow = 'double-measurement';
    } else {
        showDoubleIcon.classList.remove('hidden');
        showSingleIcon.classList.add('hidden');
        measurementTypeHide = 'double-measurement';
        measurementTypeShow = 'single-measurement';
    }

    let measurementsHide = blueprintInnerNoMeasurements.querySelectorAll(
        \`[\${ measurementTypeHide }]\`
    );
    let measurementsShow = blueprintInnerNoMeasurements.querySelectorAll(
        \`[\${ measurementTypeShow }]\`
    )

    for (m of measurementsHide)
        m.classList.add('extra-hidden')

    for (m of measurementsShow)
        m.classList.remove('extra-hidden')

    spreadAllMeasurements();
}

function moveOperationTable(x, y) {
    let t = blueprint.querySelector('g[main-operation-table]');
    t.setAttribute('transform', \`translate(\${ 50 + x } \${ 80 + y })\`)
}

function resizePartSizeTypeTable() {
    let rect = partSizesType.querySelector('rect');
    let text = partSizesType.querySelector('text');

    let { width, height, x: textX, y: textY } = text.getBoundingClientRect();
    let { x: rectX, y: rectY } = rect.getBoundingClientRect()

    let additionalBackgroundOffset = isFirefox ? 55 : 5;

    rect.setAttribute('width', width + 10);
    rect.setAttribute('height', height + 10);
    rect.setAttribute('x', textX-rectX - additionalBackgroundOffset);
    rect.setAttribute('y', textY-rectY - additionalBackgroundOffset);
    console.log(textX, rectX, additionalBackgroundOffset);
    console.log(textY, rectY, additionalBackgroundOffset);
}

function movePartSizeTextTable(x, y) {
    partSizesType.setAttribute('transform', \`translate(\${ 50 + x } \${ 50 + y })\`)
}

function resizeOperationTable() {
    let t = blueprint.querySelector('g[main-operation-table]');
    let { width, height } = t.getBoundingClientRect();
    let lines = t.querySelectorAll('.table-line-separator');
    for (line of lines) {
        line.setAttribute('x2', width - 5);
    }
    let bg = t.querySelector('rect[bg]');
    bg.setAttribute('width', width + 5);
    bg.setAttribute('height', height + 5);

    let operationIdRect = t.querySelector('rect[operation_id]');
    operationIdRect?.setAttribute('width', width + 5);
}

function showOperationTable(showDiv, yes) {
    let table = showDiv.querySelector('[operation_table]');
    let mainTable = blueprint.querySelector('[main-operation-table]');

    if (yes) {
        mainTable.innerHTML = table.innerHTML;
        resizeOperationTable();
    }
    else mainTable.innerHTML = ''
}

function showPartSizesType(yes) {
    partSizesType.style.display = yes ? '' : 'none';
}

function showMeasureIdsIfNotToggledElseShowDiv(measureIds, showDiv, yes) {
    showOperationTable(showDiv, yes);
    showPartSizesType(yes);

    let operation_id_text = showDiv.querySelector('.operation_id');
    let mac_radius_text_all = showDiv.querySelectorAll('.mac_radius');
    if (!toggleMeasurementsBool) {
        for (m of measureIds) {
            if (yes) {
                m.classList.remove('hidden');
                operation_id_text.classList.add('visible');

                for (mac_radius_text of mac_radius_text_all)
                    mac_radius_text.classList.add('visible');
            }
            else {
                m.classList.add('hidden');
                operation_id_text.classList.remove('visible');

                for (mac_radius_text of mac_radius_text_all)
                    mac_radius_text.classList.remove('visible');
            }
        }

    } else {
        showDiv.style.visibility = yes ? 'visible' : 'hidden';
    }
}

function getOperationInfoFromId(id) {
    let [operation, operation_id] = id.split('_');
    operation = operation.replace('-', '_');
    operation_id = Number(operation_id);

    return { operation, operation_id };
}
function operationInfoToId(info) {
    info.operation = info.operation.replace('_', '-');
    return \`\${info.operation}_\${info.operation_id}\`;
}

// make measurement lines longer
function isCollide(a, b) {
    let aRect = a.getBoundingClientRect();
    let bRect = b.getBoundingClientRect();

    return !(
        ((bRect.top) > (aRect.top + aRect.height)) ||
        (aRect.top > (bRect.top + bRect.height)) ||
        (bRect.left > (aRect.left + aRect.width)) ||
        (aRect.left > (bRect.left + bRect.width))
    );
}

function enlargeLine(mesLine, side, x2, y2, width2, height2) {
    if (!mesLine) return
    if (side === 'r')
        mesLine.setAttribute('x2', x2 + width2);
    else if (side === 'b')
        mesLine.setAttribute('y2', y2 + height2);
    else if (side === 'l')
        mesLine.setAttribute('x2', x2);
    else if (side === 't')
        mesLine.setAttribute('y2', y2);
}

function enlargeMeasurementsOnSide(side, offScreen, partTextMeasurements) {
    let overlaps = true;
    textMeasurements = blueprintInner.querySelectorAll(\`text[mes-group^= "\${side}"]\`) || [];

    while (overlaps) {
        overlaps = false;
        for (let i = 0; textMeasurements.length > i; i++) {
            let t1 = textMeasurements[i];
            let x = +t1.getAttribute('x');
            let y = +t1.getAttribute('y');
            let { width, height } = t1.getBoundingClientRect();

            for (let j = i + 1; textMeasurements.length > j; j++) {
                let t2 = textMeasurements[j];
                let mesGroup = t2.getAttribute('mes-group');
                let mesLine = blueprintInner.querySelector(\`line[mes-group= "\${mesGroup}"]\`);
                let x2 = +t2.getAttribute('x');
                let y2 = +t2.getAttribute('y');
                let { width: width2, height: height2 } = t2.getBoundingClientRect();

                enlargeLine(mesLine, side, x2, y2, width2, height2);
                // enlargeLine(mesLine, side, x2, y2, width2, height2);

                if (t1 === t2 || !isCollide(t1, t2)) continue;

                overlaps = true;

                if (side === 'r') {
                    x2 = x + width + 10;
                    t2.setAttribute('x', x2);
                    offScreen.right = Math.max(
                        offScreen.right,
                        x2 + width2 - blueprintInnerTranslate.x - blueprintSize.width
                    );
                }
                else if (side === 'b') {
                    y2 = y + height * (1 / scale) + 10;
                    t2.setAttribute('y', y2);
                    t2.setAttribute('transform-origin', \`\${ x2 } \${ y2 }\`);
                    offScreen.bottom = Math.max(
                        offScreen.bottom,
                        y2 + height2 - blueprintInnerTranslate.y - blueprintSize.height
                    );
                }
                else if (side === 'l') {
                    x2 = x - width2 * (1 / scale) - 10;
                    t2.setAttribute('x', x2);
                    t2.setAttribute('transform-origin', \`\${ x2 } \${ y2 }\`);
                    offScreen.left = Math.min(offScreen.left, x2);
                    if (partTextMeasurements.h.x > x2) {
                        partTextMeasurements.h.x = x2
                    }
                }
                else if (side === 't') {
                    let transform = t2.getAttribute('transform');
                    y2 = y - height2 * (1 / scale) - 15;
                    t2.setAttribute('transform', '');
                    t2.setAttribute('y', y2);
                    t2.setAttribute('transform', transform);
                    t2.setAttribute('transform-origin', \`\${ x2 } \${ y2 }\`);

                    offScreen.top = Math.min(offScreen.top, y2);
                    if (partTextMeasurements.w.y > y2) {
                        partTextMeasurements.w.y = y2
                    }
                }
                enlargeLine(mesLine, side, x2, y2, width2, height2);
            }

            if (side === 'r') {
                offScreen.right = Math.max(
                    offScreen.right,
                    x + width - blueprintInnerTranslate.x - blueprintSize.width
                );
            }
            else if (side === 'b') {
                offScreen.bottom = Math.max(
                    offScreen.bottom,
                    y + height - blueprintInnerTranslate.y - blueprintSize.height
                );
            }
            else if (side === 'l') {
                offScreen.left = Math.min(offScreen.left, x);
                if (partTextMeasurements.h.x > x) {
                    partTextMeasurements.h.x = x
                }
            }
            else if (side === 't') {
                offScreen.top = Math.min(offScreen.top, y);
                if (partTextMeasurements.w.y > y) {
                    partTextMeasurements.w.y = y
                }
            }

            if (side === 'l')
                if (partTextMeasurements.h.x > x)
                    partTextMeasurements.h.x = x
                else if (side === 't')
                    if (partTextMeasurements.w.y > y)
                        partTextMeasurements.w.y = y
        }
    }
}

function enlargePartMeasurements(partTextMeasurements, offScreen) {
    let partW = blueprintInner.querySelector('text[main-mes="w"]');
    if (!partW) return
    let partWRect = partW.getBoundingClientRect();
    let partH = blueprintInner.querySelector('text[main-mes="h"]');
    let partHRect = partH.getBoundingClientRect();

    partWX = partW.getAttribute('x');
    partWY = partTextMeasurements.w.y - partWRect.height - 50;
    partW.setAttribute('y', partWY);
    partW.setAttribute('transform-origin', \`\${ partWX } \${ partWY }\`);

    partHX = partTextMeasurements.h.x - partHRect.width - 50;
    partHY = partH.getAttribute('y');
    partH.setAttribute('x', partHX);
    partH.setAttribute('transform-origin', \`\${ partHX } \${ partHY }\`);

    let partHLineGroup = blueprintInner.querySelector('g[main-mes="h"]')
    let partWLineGroup = blueprintInner.querySelector('g[main-mes="w"]')

    for (el of partHLineGroup.children || []) {
        if (el.getAttribute('lborder'))
            el.setAttribute('x2', partHX)
        else if (el.getAttribute('lbridge')) {
            el.setAttribute('x2', partHX + 20)
            el.setAttribute('x1', partHX + 20)
        }
    }
    for (el of partWLineGroup.children || []) {
        if (el.getAttribute('lborder'))
            el.setAttribute('y2', partWY)
        else if (el.getAttribute('lbridge')) {
            el.setAttribute('y2', partWY + 20)
            el.setAttribute('y1', partWY + 20)
        }
    }

    partWRect = partW.getBoundingClientRect();
    partHRect = partH.getBoundingClientRect();

    offScreen.left = partHRect.x;
    offScreen.top = partWRect.y;
}

function enlargeMeasurementsAndResizeAndMoveBlueprint() {
    allTextMeasurements = blueprintInner.querySelectorAll('text[mes-group]') || [];

    blueprintInner.setAttribute('transform', '');
    let offScreen = { top: 0, left: 0, right: 0, bottom: 0 };
    let partTextMeasurements = {
        w: { y: 0 },
        h: { x: 0 }
    }
    // reset texts to begin positions
    for (t of allTextMeasurements) {
        let x0 = +t.getAttribute('x0');
        let y0 = +t.getAttribute('y0');
        let side = t.getAttribute('mes-group')[0];
        if (side === 'l') {
            let width = t.getBoundingClientRect().width;
            x0 -= width * (1 / scale);
        }
        else if (side === 't') {
            let height = t.getBoundingClientRect().height;
            y0 -= height * (1 / scale);
        }
        t.setAttribute('transform-origin', \`\${ x0 } \${ y0 }\`)
        t.setAttribute('x', x0);
        t.setAttribute('y', y0);
    }
    // enlarge lines
    if (toggleMeasurementsBool) {
        for (side of ['t', 'r', 'b', 'l']) {
            enlargeMeasurementsOnSide(side, offScreen, partTextMeasurements);
        }
    }
    // enlarge part measurements
    enlargePartMeasurements(partTextMeasurements, offScreen);

    let translateX = -offScreen.left * (1 / scale) + 10;
    let translateY = -offScreen.top * (1 / scale) + 10;

    blueprintInnerTranslate = { x: translateX, y: translateY };
    blueprintSize = {
        width: startBlueprintSize.width
            + translateX
            + offScreen.right
        ,
        height: startBlueprintSize.height
            + translateY
            + offScreen.bottom
        ,
    }
    blueprintInner.setAttribute(
        'transform',
        \`translate(\${ translateX } \${ translateY })\`
    );
}

// blueprintInnerNoMeasurements (BINM)
function prepareBlueprintInnerNoMeasurements() {
    // hide measurements
    let measurements = blueprintInnerNoMeasurements.querySelectorAll('.measurements') || [];
    for (m of measurements) {
        m.classList.add('hidden');
    }
    // remove white between edge and part size text
    let offScreen = { top: 0, left: 0, right: 0, bottom: 0 };
    let partTextMeasurements = {
        w: { y: 0 },
        h: { x: 0 }
    };
    blueprintInner = blueprintInnerNoMeasurements;
    enlargePartMeasurements(partTextMeasurements, offScreen);
    blueprintInner = blueprintInnerMain;
}












// spreading measurements
function getMiddleCoord(lineMeasurements, coord) {
    let attr = coord + '1';
    let sorted = lineMeasurements.sort((a) => {
        return a.getAttribute(attr)
    });
    const middleCoord = (+sorted.slice(-1)[0].getAttribute(attr) + +sorted[0].getAttribute(attr)) / 2
    return middleCoord;
}

function spreadMeasurementsForSide(measureId, measureSide) {
    if ('tb'.includes(measureSide)) {
        coord = 'x'
        axis = 'width'
    } else {
        coord = 'y'
        axis = 'height'
    }
    const lineMeasurements = Array.from(
        blueprintInnerNoMeasurements.querySelectorAll(
            \`line[measure-id= "\${measureId}"][measure-side="\${measureSide}"]\`
        )
    )
    const lineMeasurementsLength = lineMeasurements.length;

    if (!lineMeasurementsLength) return;

    measurementsType = toggleDoubleMeasurementsBool ? 'double-measurement' : 'single-measurement';
    let textMeasurements = Array.from(
        blueprintInnerNoMeasurements.querySelectorAll(
            \`[text-measurement][measure-id= "\${measureId}"][measure-side="\${measureSide}"][\${ measurementsType }]\`
        )
    );
    if (2 > textMeasurements.length || 2 > lineMeasurements.length)
        return

    let textSize = textMeasurements[0].getBoundingClientRect()[axis] / scale / 2;
    let additionalDistance = textSize * 1.2;
    let middleCoord = getMiddleCoord(lineMeasurements, coord);
    let wholeSize = textSize + additionalDistance;

    for (let i = 0; lineMeasurementsLength > i; i++) {
        line = lineMeasurements[i]
        newCoord = middleCoord
            - wholeSize * lineMeasurementsLength / 2
            + wholeSize * i

        line.setAttribute(coord + '2', newCoord)
        noMesId = line.getAttribute('no-mes-id');
        text = textMeasurements.filter(t => t.getAttribute('no-mes-id') === noMesId)[0];

        if (text.tagName === 'text') {
            text.setAttribute(coord, newCoord);
            if (coord === 'y') {
                text.setAttribute(
                    'transform-origin',
                    \`\${ text.getAttribute("x") } \${ newCoord }\`
                )
            } else {
                text.setAttribute(
                    'transform-origin',
                    \`\${ newCoord } \${ text.getAttribute("y") }\`
                )
            }
        } else if (text.tagName === 'g') {
            let x0 = +text.getAttribute('x0');
            let y0 = +text.getAttribute('y0');
            if (coord === 'y') {
                newCoordForDouble = (newCoord - y0) * scale;
                text.setAttribute('transform', \`translate(0, \${ newCoordForDouble })\`);
            } else {
                newCoordForDouble = (x0 - newCoord) * scale;
                text.setAttribute('transform', \`translate(0, \${ newCoordForDouble })\`);
            }
        }
    }
}

function spreadMeasurements(measureId) {
    for (side of 'tbrl') {
        spreadMeasurementsForSide(measureId, side)
    }
}

function spreadAllMeasurements() {
    if (toggleMeasurementsBool) return;

    const lineMeasurements = blueprintInnerNoMeasurements.querySelectorAll(
        \`line[measure-id][measure-side]\`
    )
    let spreadedMeasurements = [];
    for (line of lineMeasurements) {
        mId = line.getAttribute('measure-id')

        if (spreadedMeasurements.includes(mId))
            continue

        spreadMeasurements(mId);
        spreadedMeasurements.push(mId);
    }
}



// double measurements
function placeDoubleMeasurements() {
    const doubleMeaurements = blueprintInnerNoMeasurements.querySelectorAll(
        'g[double_measurement]'
    )

    for (dm of doubleMeaurements) {
        let upperArrow = dm.querySelector('path[arrow=tb]');
        let lowerArrow = dm.querySelector('path[arrow=bt]');
        let upperText = dm.querySelector('text[double_coord_side=t]');
        let lowerText = dm.querySelector('text[double_coord_side=b]');

        let measureSide = upperText.getAttribute('measure-side');
        let arrowHeight = lowerArrow.getBBox().height;
        let textWidth = upperText.getBBox().width;
        let x = +upperArrow.getAttribute('x0');
        let y = +upperArrow.getAttribute('y0');

        let upperArrowBaseTransform = upperArrow.getAttribute('base_transform');
        let lowerArrowBaseTransform = lowerArrow.getAttribute('base_transform');
        let arrowDistance = 5;
        let shift = arrowHeight + arrowDistance / 2;
        let textShift = 'br'.includes(measureSide) ? textWidth : -textWidth;
        textShift /= 10;

        upperArrow.setAttribute('y', y - shift - arrowDistance / 2);
        upperArrow.setAttribute(
            'transform', \`\${ upperArrowBaseTransform } translate(0, \${- shift})\`
        )
        upperArrow.setAttribute(
            'transform-origin', \`\${ x } \${ y - shift }\`
        );
        lowerArrow.setAttribute('y', y - shift);
        lowerArrow.setAttribute(
            'transform', \`\${ lowerArrowBaseTransform } translate(0, \${ shift })\`
        )
        lowerArrow.setAttribute(
            'transform-origin', \`\${ x } \${ y - shift }\`
        );

        upperText.setAttribute('y', y - arrowHeight / 2 - arrowDistance / 2);
        upperText.setAttribute('x', x + textShift);
        lowerText.setAttribute('y', y + arrowHeight / 2 + arrowDistance / 2);
        lowerText.setAttribute('x', x + textShift);
    }
}





function debounce(func, timeout=400) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    }
}

//settings text hints for buttons and add debouncing for them
function setTextHintsForButtons() {
    for (const [id, text] of Object.entries(buttonsTextHints)) {
        const element = document.getElementById(id);
        const hintTextGroup = element.querySelector('g[text-hint]');
        const bgEl = hintTextGroup.querySelector('rect');
        const textEl = hintTextGroup.querySelector('text');
        
        textEl.innerHTML = text;

        const configurePositionAndSize = () => {
            // getting textEl bbox after text assigning
            const textElBBox = textEl.getBBox();

            const xOffset = -10-textElBBox.width;
            let yOffset = 15;

            textEl.setAttribute('x', xOffset);
            textEl.setAttribute('y', yOffset);

            const bgSize = {
                width: textElBBox.width + 10, 
                height: textElBBox.height + 3,
            };

            let backgroundAdditionalYOffset = 2;

            if (isFirefox) {
                backgroundAdditionalYOffset = 5;
            }

            bgEl.setAttribute('height', bgSize.height);
            bgEl.setAttribute('width', bgSize.width);
            bgEl.setAttribute('y', yOffset-backgroundAdditionalYOffset-bgSize.height/2);
            bgEl.setAttribute('x', xOffset-(bgSize.width - textElBBox.width)/2);
        }
        configurePositionAndSize();

        // showing
        element.addEventListener('mouseover', () => {
            hintTextGroup.style.display = 'block';
            configurePositionAndSize();
        });
        element.addEventListener('mouseout', () => hintTextGroup.style.display = 'none' );
    }
}

const isFirefox = window.navigator.userAgent.includes('Firefox/');

const part = { width: ${params.partX}, height: ${params.partY} };
const blueprint = document.getElementById('blueprint');
let scale = ${params.scale};
const startBlueprintSize = JSON.parse(\`${JSON.stringify(params.blueprintSize)}\`);
const partSizesTypeText = "${params.partSizesType}";

const blueprintInnerMain = blueprint.querySelector('g[inner]');
const blueprintInnerNoMeasurements = blueprint.querySelector('g[inner_no_measurements]');
let blueprintInner = blueprintInnerMain;
let blueprintSize = startBlueprintSize;
let tempScale = 1;
let blueprintInnerTranslate = { x: 0, y: 0 };
const type_svg = "${params.typeSvg}";
let blueprintContainer = blueprint.querySelector('[blueprint_container]');
let blueprintBackground = blueprint.getElementById('blueprint-background');
const buttonsTextHints = JSON.parse(\`${JSON.stringify(params.textHints || {})}\`)


document.addEventListener("DOMContentLoaded", () => {
    window.parent.postMessage({
        type: 'initial-operation',
        message: {}
    })
});

document.addEventListener("keydown", event => {
    if (event.key === 'Escape') {
        window.parent.postMessage({
            type: 'close-operation-page',
            message: {}
        });
    }
});
// communicating with react
const handler = (ev) => {
    if (typeof ev.data !== 'object') return
    if (!ev.data.type) return
    if (!ev.data.message) return

    switch (ev.data.type) {
        case 'initial-operation-frame': {
            for (let [operation, operation_ids] of Object.entries(ev.data.message.operations)) {
                for (operation_id of operation_ids) {
                    let id = operationInfoToId({ operation, operation_id });
                    let elements = blueprintInner.querySelectorAll(\`[hover_show="\${id}"]\`);

                    for (e of elements) {
                        e.classList.add('blink');
                    }
                }
            }
            return;
        }
        case 'blink-operation-on': {
            let id = operationInfoToId(ev.data.message);
            let elements = blueprintInner.querySelectorAll(\`[hover_show="\${id}"]\`);

            for (e of elements) {
                e.classList.add('blink');
            }
            return;
        }
        case 'blink-operation-off': {
            let id = operationInfoToId(ev.data.message);
            let elements = blueprintInner.querySelectorAll(\`[hover_show = "\${id}"]\`);

            for (e of elements) {
                e.classList.remove('blink');
            }
            return;
        }
        case 'blink-reset': {
            for (e of blueprintInner.querySelectorAll('.blink'))
                e.classList.remove('blink');
            return;
        }
        default: {
            return;
        }
    }
}
window.addEventListener('message', handler)



const zoomIn = blueprint.getElementById('blueprint-zoom-in');
const zoomOut = blueprint.getElementById('blueprint-zoom-out');
const toggleMeasurements = blueprint.getElementById('blueprint-toggle-measurements');
const toggleIDs = blueprint.getElementById('blueprint-show-id');
const resizeScrollToCenter = blueprint.getElementById('blueprint-resize-scroll-to-center');
const toggleDoubleMeasurements = blueprint.getElementById('blueprint-toggle-double-measurements');
const mainOperationTable = blueprint.querySelector('[main-operation-table]');
const partSizesType = blueprint.querySelector('[part-sizes-type]');
partSizesType.querySelector('text').innerHTML = partSizesTypeText;


// if firefox, show table first
if (isFirefox) {
    showPartSizesType(true);
}

resizePartSizeTypeTable();

// if firefox, hide table after resize
if (isFirefox) {
    showPartSizesType(false);
}

let toggleMeasurementsBool = true;
let toggleIDsBool = false;
let toggleDoubleMeasurementsBool = localStorage.getItem('BlueprintToggleDoubleMeasurementsBool') === 'true';
const parentElement = (window || blueprint.parentElement || window);

zoomIn.addEventListener('click', () => {
    scale += .1;
    scaleBlueprint();
    spreadAllMeasurements();
});
zoomOut.addEventListener('click', () => {
    scale -= (scale - .1) > .1 ? .1 : 0;
    scaleBlueprint();
    spreadAllMeasurements();
});
toggleMeasurements.addEventListener('click', toggleMeasurementsHandler);
toggleIDs.addEventListener('click', toggleIDsHandler)
resizeScrollToCenter.addEventListener('click', resizeScrollToCenterHandler)
toggleDoubleMeasurements.addEventListener('click', toggleDoubleMeasurementsHandler)
parentElement.addEventListener('resize', updateButtonsLocationAndCenterBlueprint);
parentElement.addEventListener('scroll', updateButtonsLocationAndCenterBlueprint);



// showing comments
// for (b of [blueprintInnerMain, blueprintInnerNoMeasurements]) {
for (b of [blueprintInnerMain, blueprintInnerNoMeasurements]) {
    for (e of b.querySelectorAll('[hover_show]') || []) {
        let id = e.getAttribute('hover_show');
        let { operation, operation_id } = getOperationInfoFromId(id);

        // communicating with react
        e.addEventListener('click', (ev) => {
            if (2 > id.split('_').length)
                return;

            let type;
            if (ev.detail === 1) {
                type = 'toggle-operation';
                for (e of blueprintInner.querySelectorAll(\`[hover_show = "\${id}"]\`))
                    e.classList.toggle('blink');
            } else {
                type = 'edit-operation';
            }

            window.parent.postMessage({
                type,
                message: {
                    operation,
                    operation_id
                }
            }, '*');
        })
        // end

        let showDiv = b.querySelector(\`#\${id}\`);
        let showDivChildren = showDiv ? showDiv.children : [];
        let measureIds = b.querySelectorAll(\`[measure-id= "\${id}"]\`) || []

        for (ee of showDivChildren) {
            ee.addEventListener('mouseover', () => {
                showMeasureIdsIfNotToggledElseShowDiv(measureIds, showDiv, true);
            });
            ee.addEventListener('mouseout', () => {
                showMeasureIdsIfNotToggledElseShowDiv(measureIds, showDiv, false);
            });
        }

        for (m of measureIds) {
            m.addEventListener('mouseover', () => {
                showMeasureIdsIfNotToggledElseShowDiv(measureIds, showDiv, true);
            });
            m.addEventListener('mouseout', () => {
                showMeasureIdsIfNotToggledElseShowDiv(measureIds, showDiv, false);
            });
        }

        e.addEventListener('mouseover', () => {
            showMeasureIdsIfNotToggledElseShowDiv(measureIds, showDiv, true);
        });
        e.addEventListener('mouseout', () => {
            showMeasureIdsIfNotToggledElseShowDiv(measureIds, showDiv, false);
        });
    }
}



// setButtonActive(toggleDoubleMeasurements, true);
setTextHintsForButtons();
prepareBlueprintInnerNoMeasurements();
scaleBlueprint();

placeDoubleMeasurements()
toggleDoubleMeasurementsHandler(false)

if (['no_sizes', 'no_size'].includes(type_svg)) {
    toggleMeasurementsHandler()
}

function getoffside() {
    return blueprintInner.querySelector('g[part_side=f]');
}
`)